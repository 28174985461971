import React from 'react';

class LocaleCheckbox extends React.Component {

    constructor(props) {

        super(props);
        this.onChecked = this.onChecked.bind(this);
    }

    onChecked(e) {

        this.props.onChange(e.target.checked);
    }

    render() {

        return (
            <div className="form-check">
              <label className="form-check-label">
                <input type="checkbox" className="form-check-input" checked={this.props.checked} onChange={this.onChecked} />
                Enable Locale Fields
              </label>
            </div>
        );
    }
}

export default LocaleCheckbox;
