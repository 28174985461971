import React from 'react';
import OrgPrivacyIdCapsule from './org-privacy-id-capsule';
import SummaryHeader from '../../../components/shared/summary-header';

class OrganizationSettings extends React.Component {

    constructor(props) {
        super(props);

        this.onLinkedTrustClicked = this.onLinkedTrustClicked.bind(this);
    }

    onLinkedTrustClicked() {

        this.props.onLinkedTrustClicked();
    }

    render() {

        return (
            <ul style={{ marginTop: '2%' }} className="list-group">
                <SummaryHeader name="Settings" />
                <li className="list-group-item text-center">
                    <label>
                <input type="checkbox" defaultChecked={this.props.linkedTrust} onClick={this.onLinkedTrustClicked} />
                        &nbsp;&nbsp;&nbsp;Enable Linked Trust
                    </label>
                    <OrgPrivacyIdCapsule
                        privacyId={this.props.privacyId}
                        onChange={this.props.onPrivacyIdChanged}
                    />
                </li>
            </ul>
        );
    }
}

export default OrganizationSettings;
