import React, { Component } from 'react';
import SummaryHeader from '../../../components/shared/summary-header';
import AppCredentialsPanel from './app-credentials-panel';
import AppControlsPanel from './app-controls-panel';
import AppTypeCapsule from './app-type-capsule';
import ChannelIdCapsule from './app-channel-id-capsule';
import PrivacyIdCapsule from './app-privacy-id-capsule';
import ProviderCapsule from './provider-capsule';
import OauthFeaturesCapsule from './oauth-features-capsule';
import LinksCapsule from './links-capsule';
import PortalContentCapsule from './portal-content-capsule';
import RedirectsPanel from '../../../components/shared/redirects-panel';
import RegistrationFeaturesCapsule from './registration-features-capsule';
import PermissionsPanel from '../../../components/shared/permissions-panel';

class AppManagePanel extends Component {

    render() {
        const {
            application
        } = this.props;

        if (!application) {
            return null;
        }

        let redirectUrlsPlaceholder = '';
        if (application.redirectUrls.length === 0) {
            redirectUrlsPlaceholder = 'Add a redirect here.';
        }
        else {
            redirectUrlsPlaceholder = 'Add more redirects here.';
        }

        let permissionsPlaceholder = '';
        if (application.permissions.length === 0) {
            permissionsPlaceholder = 'Add a permission here.';
        }
        else {
            permissionsPlaceholder = 'Add more permissions here.';
        }

        return (
            <div className="container container-fluid">
                <div className="row">
                    <div className="col-12">
                        <ul className="list-group">
                            <SummaryHeader name="Manage" />
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-6" style={{ borderRight: '1px solid #DFDFDF' }}>
                                        <AppCredentialsPanel
                                            credentials={application.credentials}
                                            onReset={this.props.onClientSecretReset}
                                            onUpdateStatus={this.props.onUpdateStatus}
                                            />
                                        <PermissionsPanel
                                            title="Permissions"
                                            placeholder={permissionsPlaceholder}
                                            permissions={application.permissions}
                                            onAdd={this.props.onPermissionAdded}
                                            onRemove={this.props.onPermissionRemoved}
                                            />
                                        <RedirectsPanel
                                            title="Redirects"
                                            placeholder={redirectUrlsPlaceholder}
                                            urls={application.redirectUrls}
                                            onAdd={this.props.onDomainAdded}
                                            onRemove={this.props.onDomainRemoved}
                                            />
                                        <AppTypeCapsule
                                            onChange={this.props.onAppTypeChanged}
                                            selected={application.flowManagement.applicationType} />
                                        <ProviderCapsule
                                            providers={this.props.providers}
                                            onChange={this.props.onProviderChanged}
                                            disableNull="true"
                                            selected={application.authenticationProvider} />
                                        <ChannelIdCapsule
                                            onChange={this.props.onChannelIdChanged}
                                            channelId={application.channelId} />
                                        <PrivacyIdCapsule
                                          onChange={this.props.onPrivacyIdChanged}
                                          privacyId={application.privacy ? application.privacy.id : null} />
                                        <RegistrationFeaturesCapsule
                                            onLocaleChanged={this.props.onLocaleChanged}
                                            localeChecked={application.registration ? application.registration.localeEnabled : null} />
                                        <OauthFeaturesCapsule
                                            delegate={application.flowManagement.delegate}
                                            onDelegateChanged={this.props.onDelegateChanged}
                                            onDelegateWhitelistChanged={this.props.onDelegateWhitelistChanged}
                                            onDelegateWhitelistAdded={this.props.onDelegateWhitelistAdded}
                                            onDelegateWhitelistRemoved={this.props.onDelegateWhitelistRemoved}
                                            onPkceChanged={this.props.onPkceChanged}
                                            pkceChecked={application.flowManagement.pkceEnabled}
                                            onOverrideEmailValidationChanged={this.props.onOverrideEmailValidationChanged}
                                            overrideEmailValidationChecked={application.flowManagement.overrideEmailValidation}
                                            onPasswordChanged={this.props.onPasswordChanged}
                                            passwordChecked={application.flowManagement.passwordEnabled} />
                                    </div>
                                    <div className="col-6 align-items-center justify-content-center">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h5 className="text-center">Portal Look and Feel</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <LinksCapsule
                                                        onPrivacyPolicyUpdated={this.props.onPrivacyPolicyUpdated}
                                                        onTermsAndConditionsUpdated={this.props.onTermsAndConditionsUpdated}
                                                        onMoreInfoUpdated={this.props.onMoreInfoUpdated}
                                                        privacyPolicyUrl={application.displayable.privacyPolicyUrl}
                                                        termsAndConditionsUrl={application.displayable.termsAndConditionsUrl}
                                                        moreInfoUrl={application.displayable.content.moreInfoUrl} />
                                                </div>
                                                <div className="row">
                                                    <PortalContentCapsule
                                                        title={application.displayable.content.title}
                                                        appIcon={application.displayable.content.appIconUrl}
                                                        onTitleUpdated={this.props.onTitleUpdated}
                                                        onAppIconUpdated={this.props.onAppIconUpdated} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <hr />
                                            </div>
                                        </div>
                                        <AppControlsPanel
                                            organizationId={application.organization._id}
                                            applicationId={application._id}
                                            applicationActiveState={application.active}
                                            applicationImplicitState={application.implicit}
                                            onImplicitChange={this.props.onImplicitChange}
                                            onToggleApplication={this.props.onToggleApplication}
                                            onDeleteApplication={this.props.onDeleteApplication} />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppManagePanel;
