import React, { Component } from 'react';
import LocaleCheckbox from '../../../components/shared/locale-checkbox';

class RegistrationFeaturesCapsule extends Component {

    render() {

        return (
            <div className="row" style={{ marginTop: '1%' }}>
                <div className="col-12">
                    <span className="text-left">
                        <strong>Registration Features</strong>
                    </span>
                    <div style={{ marginLeft: '0.5rem' }}>
                        <LocaleCheckbox onChange={this.props.onLocaleChanged}
                                      checked={this.props.localeChecked} />
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistrationFeaturesCapsule;
