import React, { Component } from 'react';
import {
    Redirect
} from 'react-router-dom';
import Api from '../../../components/shared/api';
import DetailHeader from './detail-header';
import DetailBody from './detail-body';
import DetailFooter from './detail-footer';
import FormError from '../../../components/shared/form-error';

class DetailContainer extends Component {

    constructor() {

        super();
        this.state = {
            redirect: null,
            updated: false,
            organization: {
                applications: [],
                members: [],
                createdBy: {},
                linkedTrust: false
            }
        };
        this.api = new Api();
        this.onChangeSecret = this.onChangeSecret.bind(this);
        this.onDeleteCredentials = this.onDeleteCredentials.bind(this);
        this.onDeleteOrganization = this.onDeleteOrganization.bind(this);
        this.onGenerateCredentials = this.onGenerateCredentials.bind(this);
        this.onGridAltered = this.onGridAltered.bind(this);
        this.onLinkedTrustClicked = this.onLinkedTrustClicked.bind(this);
        this.onPrivacyIdChanged = this.onPrivacyIdChanged.bind(this);
    }

    componentDidMount() {

        this.api.exec(`/user/organizations/${this.props.id}`)
        .then((organization) => {

            this.setState({ organization, hasMounted: true });
        })
        .catch((err) => {

            console.error(err);
            alert(`An error occurred. Please refresh to try again.`);
        });
    }

    onChangeSecret() {
        this.api.exec(`/user/organizations/${this.props.id}`, 'PUT', { accessSecret: true })
        .then((data) => {
            const organization = Object.assign({}, this.state.organization);
            organization.credentials = data.credentials;
            this.setState({ organization });
        })
        .catch((err) => {

            console.error(err);
            alert('A problem occurred. Please try again later.');
        });
    }

    onDeleteCredentials() {
        this.api.exec(`/user/organizations/${this.props.id}`, 'PUT', { deleteCredentials: true })
        .then((data) => {
            const organization = Object.assign({}, this.state.organization);
            organization.credentials = data.credentials;
            this.setState({ organization });
        })
        .catch((err) => {

            console.error(err);
            alert('A problem occurred. Please try again later.');
        });
    }

    onDeleteOrganization($alert) {

        if ($alert) {
            return this.setState({ error: $alert });
        }

        this.api.exec(`/user/organizations`, 'DELETE', { organizationId: this.props.id })
        .then(() => {

            this.setState({ redirect: '/' });
        })
        .catch((err) => {

            console.error(err);
            alert(`A problem occurred. Please try again later.`);
        });
    }

    onGenerateCredentials() {
        this.api.exec(`/user/organizations/${this.props.id}`, 'PUT', { generateCredentials: true })
        .then((data) => {
            const organization = Object.assign({}, this.state.organization);
            organization.credentials = data.credentials;
            this.setState({ organization });
        })
        .catch((err) => {

            console.error(err);
            alert('A problem occurred. Please try again later.');
        });
    }

    onGridAltered(which, array) {

        const organization = this.state.organization;

        organization[which] = array;

        this.setState({ organization });
    }

    onLinkedTrustClicked() {

        const linkedTrust = !this.state.organization.linkedTrust;

        this.api.exec(`/user/organizations/${this.props.id}`, 'PUT', { linkedTrust })
        .then(() => {

            const organization = Object.assign({}, this.state.organization);
            organization.linkedTrust = linkedTrust;
            this.setState({ organization });
        })
        .catch((err) => {

            console.error(err);
            alert('A problem occurred. Please try again later.');
        });
    }

    onPrivacyIdChanged(privacyId) {
      if (privacyId === '') {
        privacyId = null;
      }
      this.api.exec(`/user/organizations/${this.props.id}`, 'PUT', { privacyId })
        .then(() => {

          const organization = Object.assign({}, this.state.organization);
          organization.privacy.id = privacyId;
          this.setState({ organization });
        })
        .catch((err) => {

          console.error(err);
          alert('A problem occurred. Please try again later.');
        });
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const { organization } = this.state;

        if (!organization || this.state.hasMounted !== true) {
            return null;
        }

        const {
            name,
            linkedTrust,
            createdAt,
            credentials,
            updatedAt,
            createdBy,
            applications,
            members,
            privacy
        } = organization;

        return (
            <div>
                <DetailHeader name={name}
                              created={createdAt}
                              updated={updatedAt}
                              creator={createdBy}
                              />
                <FormError title="Error" width="12" offset="0" message={this.state.error} />
                <FormError title="Success" state="success" width="10" offset="0" message={this.props.updated === 'true' ? 'Organization successfully updated.' : null} />
                <DetailBody organizationId={this.props.id}
                            linkedTrust={linkedTrust}
                            applications={applications}
                            credentials={credentials}
                            members={members}
                            privacyId={privacy ? privacy.id : null}
                            creator={createdBy}
                            onChangeSecret={this.onChangeSecret}
                            onDeleteCredentials={this.onDeleteCredentials}
                            onGenerateCredentials={this.onGenerateCredentials}
                            onGridAltered={this.onGridAltered}
                            onLinkedTrustClicked={this.onLinkedTrustClicked}
                            onPrivacyIdChanged={this.onPrivacyIdChanged}
                             />
                <DetailFooter
                    hasApplications={applications.length > 0}
                    onDelete={this.onDeleteOrganization} />
            </div>
        );
    }
}

export {
    DetailContainer
};
